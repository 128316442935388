'use client';

import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { ImageResponseData, ImageSearchRequest } from "@/restapi/image";
import ImageGridCard, { ImageGridCardDeleteOptions } from "../../../ui/card/ImageGridCard";
import { FilterImageCategory } from "../hooks/useGalleryCategory";


type Props = {
  loadMore: () => void;
  hasMore: boolean;
  items: ImageResponseData[];
  category: FilterImageCategory;
  handleChangeScReenType: (imageId: string) => void;
  className?: string;
  deleteOptions?: ImageGridCardDeleteOptions | undefined
};

const GalleryGrid: React.FC<Props> = ({ loadMore, handleChangeScReenType, hasMore, category, items, deleteOptions, className = 'bg-black' }) => {

  return (
    <div className={`max-w-5xl flex justify-center ${className}`}>
      <InfiniteScroll
        key={`infinite-scroll-${JSON.stringify(items)}-${category}`}
        dataLength={items.length}
        next={loadMore}
        hasMore={hasMore}
        loader={<div></div>}
      >
        <div className="grid grid-cols-3 w-full max-w-5xl mx-auto px-4 ">
          {items.map((image, index) => (
            <ImageGridCard
              deleteOptions={deleteOptions}
              key={`${category}-${image.uuid}-${index}`}
              image={image}
              onClick={() => handleChangeScReenType(image.uuid)}
            />
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default GalleryGrid;