'use client';

import React, { useState } from "react";
import { ImageSearchRequest } from "@/restapi/image";
import { useImageSearch } from "@/hooks/image/useImageSearch";
import { FilterImageCategory } from "./hooks/useGalleryCategory";
import RandomizedMasonryGrid from "./grids/RandomizedMasonryGrid";
import GalleryGrid from "./grids/GalleryGrid";
import { ImageGridCardDeleteOptions } from "@/components/ui/card/ImageGridCard";
import { ScreenType, useAppContext } from "@/context/AppContextProvider";
import useEcho from "@/hooks/useEcho";
import FullScreenGallery from "./FullScreenGallery";

type Props = {
  request: ImageSearchRequest;
  gallaryCategory: FilterImageCategory;
  screenType: ScreenType;
  changeScreenType: (screenType: ScreenType) => void;
  deleteOptions?: ImageGridCardDeleteOptions | undefined
  className?: string;
  defaultScreenType?: ScreenType;
  refreshInterval?: number;
};

const InfiniteScrollGallery: React.FC<Props> = ({ gallaryCategory, request, screenType, changeScreenType, deleteOptions, className, defaultScreenType = 'grid', refreshInterval, }) => {
  const [selectedImageId, setSelectedImageId] = useState<string | null>(null);
  const { items, isError, isLoading, hasMore, loadMore } = useImageSearch({
    ...request,
    refreshInterval,
  }, 1);

  // const [items, setItems] = useState<ImageResponseData[] | undefined>(_items);

  const handleChangeScReenType = (imageId: string) => {
    setSelectedImageId(imageId);
    changeScreenType('fullscreen');
  }

  const revertScreenType = () => {
    changeScreenType(defaultScreenType);
  }

  // useEffect(() => {
  //   if (!isLoading && !isError && _items) {
  //     setItems(_items);
  //   }
  // }, [_items])


  // // 画像投稿時のリアルタイム反映処理
  // const echoPost = useEcho();
  // useEffect(() => {
  //   if (echoPost) {
  //     echoPost
  //       .channel(`channel-image-new-post`)
  //       .listen("ImageNewPostEvent", (response: ImagePostEventResponse) => {
  //         // 画像投稿後にスコアされてない画像と新着投稿に追加
  //         const { image } = response;
  //         if (items) {
  //           setItems(pre => [
  //             image,
  //             ...items
  //           ]);
  //         }
  //       });
  //   }
  // }, [echoPost]);

  // // 画像削除時のリアルタイム反映処理
  // const echoDelete = useEcho();
  // useEffect(() => {
  //   if (echoDelete) {
  //     echoDelete
  //       .channel(`channel-image-delete`)
  //       .listen("ImageNewDeleteEvent", (response: ImageDeleteEventResponse) => {
  //         // 画像削除完了後に各画像一覧リストを更新
  //         const { imageId } = response;
  //         setLatestItems(latestItems.filter((item) => item.uuid !== imageId));
  //         setNotScoredItems(
  //           notScoredItems.filter((item) => item.uuid !== imageId)
  //         );
  //         setHighestItems(highestItems.filter((item) => item.uuid !== imageId));
  //         setLowestItems(lowestItems.filter((item) => item.uuid !== imageId));
  //         setFollowingItems(
  //           followingItems.filter((item) => item.uuid !== imageId)
  //         );
  //       });
  //   }
  // }, [echoDelete]);

  if (isError) {
    return <></>;
  }

  if (isLoading) {
    return (
      <></>
    );
  }

  if (!items) {
    return null;
  }


  switch (screenType) {
    // 全画面表示に切り替え
    case 'fullscreen':
      {
        return (
          <FullScreenGallery
            images={items}
            selectedImageId={selectedImageId ?? undefined}
            filterImageCategory={gallaryCategory}
            loadMore={loadMore}
            hasMore={hasMore}
            revertScreenType={revertScreenType}
          />
        );
      }

    // ランキンググリッド
    case 'grid':
      return (
        <GalleryGrid
          items={items}
          loadMore={loadMore}
          hasMore={hasMore}
          category={gallaryCategory}
          handleChangeScReenType={handleChangeScReenType}
          className={className}
          deleteOptions={deleteOptions}
        />
      );
    // ランダム画像一覧
    case 'random':
      return (
        <RandomizedMasonryGrid
          items={items}
          loadMore={loadMore}
          hasMore={hasMore}
          isLoading={isLoading}
          category={gallaryCategory}
          handleChangeScReenType={handleChangeScReenType}
          className={className}
        />
      );

    default:
      return <></>;
  }
};

export default InfiniteScrollGallery;