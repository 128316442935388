'use client';

import React from "react";
import Image from "next/image";
import { ImageResponseData } from "@/restapi/image";
import { isHttps } from "@/lib/utils";
import DeleteCheckbox from "@/components/history/inputs/DeleteCheckbox";
import "./style.css";

export type ImageGridCardDeleteOptions = {
  isDeleteMode: boolean; // 削除モードかどうか
  selectedToDelete: string | null; // 選択された画像のID
  handleDeleteImageCheck: (imageId: string) => void; // チェックボックス選択時の処理
};

type ImageGridCardProps = {
  image: ImageResponseData;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>, imageId: string) => void;
  deleteOptions?: ImageGridCardDeleteOptions;
};

const ImageGridCard: React.FC<ImageGridCardProps> = ({
  image,
  onClick,
  deleteOptions,
}) => {
  const isChecked = deleteOptions?.selectedToDelete === image.uuid;
  const showCheckbox = deleteOptions?.isDeleteMode && (!deleteOptions?.selectedToDelete || isChecked);

  return (
    <div className="relative group grid [grid-template-areas:stack] overflow-hidden rankScoreList">
      {/* 画像クリックでフルスクリーン表示 */}
      <div className="cursor-pointer" onClick={(e) => onClick?.(e, image.uuid)}>
        {isHttps(image.imageFile.fileUrl) ? (
          <Image
            width={300}
            height={300}
            alt={image.imageFile.fileName}
            className="[grid-area:stack] object-cover w-full aspect-square rounded-lg"
            src={image.imageFile.summaryFileUrl ?? image.imageFile.fileUrl}
            priority
          />
        ) : (
          <img
            width={300}
            height={300}
            alt={image.imageFile.fileName}
            className="[grid-area:stack] object-cover w-full aspect-square rounded-lg"
            src={image.imageFile.fileUrl}
          />
        )}

        {/* 画像のスコアを表示 */}
        {typeof image.averageScore === "number" && !isNaN(image.averageScore) && (
          <div className="text-with-outline transform rankScoreTxtBox">
            <span className="rankScoreTxt">{image.averageScore}</span>
          </div>
        )}
      </div>
      {/* 削除モード時のチェックボックス（1つ選択すると他は消える） */}
      {showCheckbox && (
        <div className="absolute top-2 right-2">
          <DeleteCheckbox
            checked={isChecked}
            onClickCallback={() => deleteOptions?.handleDeleteImageCheck(image.uuid)}
          />
        </div>
      )}
    </div>
  );
};

export default ImageGridCard;