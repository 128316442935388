"use client";

import { FC, useEffect, useRef, useState } from "react";
import ImageScoreCard from "./components/ImageScoreCard";
import { ImageResponseData } from "@/restapi/image";
import InfiniteScroll from "react-infinite-scroll-component";
import { usePathname } from "next/navigation";
import { FilterImageCategory } from "../hooks/useGalleryCategory";

/**
 * 全画面表示ギャラリー
 */
const FullScreenGallery: FC<{
  selectedImageId?: string;
  images: ImageResponseData[];
  loadMore: () => void;
  hasMore: boolean;
  filterImageCategory: FilterImageCategory;
  revertScreenType: () => void;
}> = ({ images, selectedImageId, filterImageCategory, loadMore, hasMore, revertScreenType }) => {
  const pathname = usePathname();
  const ref = useRef<HTMLDivElement>(null);
  const initialized = useRef(false);
  const [items, setItems] = useState<ImageResponseData[]>([]);
  const [prevImages, setPrevImages] = useState<ImageResponseData[]>([]);
  const isScrolling = useRef(false);
  const lastScrollY = useRef(0);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    if (!selectedImageId || images.length === 0) return;

    const selectedIndex = images.findIndex((img) => img.uuid === selectedImageId);
    if (selectedIndex === -1) return;

    const startImages = images.slice(Math.max(0, selectedIndex - 5), selectedIndex + 6);
    setItems(startImages);

    setPrevImages(images.slice(0, Math.max(0, selectedIndex - 5)));
  }, [selectedImageId, images]);

  /** スムーズに選択画像までスクロール（位置補正） */
  useEffect(() => {
    if (!selectedImageId || initialized.current) return;
    initialized.current = true;
    setTimeout(() => {
      const targetElement = document.getElementById(`image-${selectedImageId}`);
      if (targetElement) {
        isScrolling.current = true;
        targetElement.scrollIntoView({ behavior: "auto", block: "center" });

        setTimeout(() => {
          isScrolling.current = false;
        }, 600);
      }
      setScrolled(true);
    }, 500);
  }, [selectedImageId]);

  /** 上スクロール時に `prevImages` を `items` に追加し、スクロール位置を適切に補正 */
  useEffect(() => {
    if (!ref.current) return;

    const currentRef = ref.current;

    const handleScroll = () => {
      if (!currentRef || isScrolling.current) return;

      const scrollTop = currentRef.scrollTop;
      const scrollDelta = scrollTop - lastScrollY.current;

      // **上スクロール時に画像を追加**
      if (scrollDelta < 0 && scrollTop < 50 && prevImages.length > 0) {
        const addCount = Math.min(5, prevImages.length);
        const newItems = prevImages.slice(-addCount);
        const previousHeight = currentRef.scrollHeight; // 追加前の高さ

        setItems((prev) => [...newItems, ...prev]);

        setPrevImages((prevState) => {
          const updatedPrev = prevState.slice(0, -addCount);
          return updatedPrev;
        });

        // **追加後にスクロール位置を補正**
        setTimeout(() => {
          const addedHeight = currentRef.scrollHeight - previousHeight;
          currentRef.scrollTop += addedHeight; // 高さ増加分を補正
        }, 1000);
      }

      lastScrollY.current = scrollTop;
    };

    // **requestAnimationFrame で最適化**
    let ticking = false;
    const smoothScrollHandler = () => {
      if (!ticking) {
        requestAnimationFrame(() => {
          handleScroll();
          ticking = false;
        });
        ticking = true;
      }
    };

    currentRef.addEventListener("scroll", smoothScrollHandler);

    return () => {
      currentRef.removeEventListener("scroll", smoothScrollHandler);
    };
  }, [prevImages]);

  return (
    <div ref={ref} className={`${scrolled ? 'opacity-100' : 'opacity-0'} w-full min-h-screen transition-opacity duration-200 overflow-y-auto`}>
      <InfiniteScroll
        dataLength={items.length}
        next={loadMore}
        hasMore={hasMore}
        loader={<div className="loader"></div>}
        inverse={true}
      >
        <div className="slide" id="scrollable-div">
          {items.length === 0 && (
            <div className="text-white text-center p-10">画像が見つかりません</div>
          )}
          {items.map((img, index) => (
            <div
              key={`${filterImageCategory}-${img.uuid}-${index}`}
              id={`image-${img.uuid}`}
              className="snap-item transition-all duration-500 transform"
            >
              <ImageScoreCard key={`${pathname}${img.uuid}-${index}`} image={img} />
            </div>
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default FullScreenGallery;