"use client";

import { FilterImageCategory, TabItem } from "../hooks/useGalleryCategory";
import React, { FC, useEffect } from "react";


type Props = {
  tabs: TabItem[];
  category: FilterImageCategory;
  handleCategoryChange: (selectedTab: FilterImageCategory) => void
  className?: string;
  buttonClassName?: string;
}

const GallaryTabSwitcher: FC<Props> = ({ tabs, handleCategoryChange, category, className = "", buttonClassName = ""  }) => {

  // リサイズイベントを発火して画面のレイアウトを調整
  useEffect(() => {
    setTimeout(() => {
      if (window !== undefined) {
        window.dispatchEvent(new Event("resize"));
      }
    }, 100);
  }, []);

  return (
    <div className={`text-white flex px-4 ${className}`}>
      {tabs.map((tab) => (
        <button
          key={String(tab.value)}
          onClick={(e) => { e.stopPropagation(); handleCategoryChange(tab.value) }}
          className={`text-[min(3vw,14px)] mx-2 px-2 ${buttonClassName} ${tab.className ?? ''} ${category === tab.value ? 'border-b-2 border-gray-100 rounded-none' : ''}`}
        >
          {tab.label}
        </button>
      ))}
    </div>
  );
};

export default GallaryTabSwitcher;