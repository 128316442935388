"use client";

import React from "react";
import Image from "next/image";
import "./style.css";

type Props = {
  averageScore?: number | null;
  src: string;
  alt?: string;
  id: string;
}

const ImageShuffledCard: React.FC<{
  params: Props;
  onClick: (imageId: string) => void;
  className?: string;
}> = ({ params, onClick, className = '' }) => {
  const { src, alt, id, averageScore } = params;

  return (
    <div className={`${className ?? ''} relative`}>
      <div className="cursor-pointer w-full h-full" onClick={(event) => { event.stopPropagation(); onClick(id); }}>
        <Image
          width={300}
          height={300}
          alt={alt ?? ''}
          className="object-cover w-full h-full aspect-[4/3] rounded-lg"
          src={src}
        />
        {typeof averageScore === "number" &&
          !isNaN(averageScore) && (
            <div className="text-with-outline transform rankScoreTxtBox">
              <span className="rankScoreTxt">
                {averageScore}
              </span>
            </div>
          )}
      </div>
    </div>
  );
};
export default ImageShuffledCard;