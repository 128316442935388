import { ImageResponseData } from "@/restapi/image";
import { FC, useEffect, useState, useCallback } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { FilterImageCategory } from "../hooks/useGalleryCategory";
import { getRandomNumberInRange } from "@/lib/utils";
import ImageShuffledCard from "@/components/ui/card/ImageShuffledCard";
import clsx from "clsx";

// 5行ごとのレイアウトパターン
const predefinedLayouts = [
  [
    "row-span-3 col-span-1",
    "row-span-2 col-span-1",
    "row-span-2 col-span-1",
    "row-span-1 col-span-1",
    "col-span-2 row-span-2",
  ],
  [
    "col-span-2 row-span-2",
    "row-span-2 col-span-1",
    "row-span-1 col-span-1",
    "row-span-2 col-span-1",
    "row-span-1 col-span-1",
  ],
  [
    "row-span-1 col-span-1",
    "row-span-2 col-span-1",
    "row-span-2 col-span-1",
    "row-span-2 col-span-1",
    "col-span-1 row-span-1",
  ],
  [
    "row-span-2 col-span-1",
    "row-span-1 col-span-1",
    "row-span-2 col-span-1",
    "row-span-1 col-span-1",
    "col-span-2 row-span-2",
  ],
  [
    "row-span-2 col-span-1",
    "row-span-1 col-span-1",
    "row-span-3 col-span-1",
    "row-span-2 col-span-1",
    "col-span-2 row-span-2",
  ],
];

type Props = {
  loadMore: () => void;
  hasMore: boolean;
  isLoading: boolean;
  items: ImageResponseData[];
  category: FilterImageCategory;
  handleChangeScReenType: (imageId: string) => void;
  className?: string;
};

const RandomizedMasonryGrid: FC<Props> = ({
  loadMore,
  handleChangeScReenType,
  hasMore,
  category,
  isLoading,
  items,
  className,
}) => {
  const [layoutIndexes, setLayoutIndexes] = useState<number[]>([]);

  // 各画像のクラスを取得
  const getClassName = useCallback(
    (index: number) => {
      const groupIndex = Math.floor(index / 5);
      const selectedLayout = predefinedLayouts[layoutIndexes[groupIndex] ?? 0];
      return selectedLayout[index % selectedLayout.length];
    },
    [layoutIndexes]
  );

  useEffect(() => {
    if (!isLoading && items.length > layoutIndexes.length * 5) {
      setLayoutIndexes((prev) => {
        const newLayouts = [...prev];
        while (newLayouts.length * 5 < items.length) {
          newLayouts.push(getRandomNumberInRange({ min: 0, max: predefinedLayouts.length - 1 }));
        }
        return newLayouts;
      });
    }
  }, [isLoading, items.length]);

  return (
    <div className={clsx("max-w-5xl flex justify-center p-4", className)}>
      <InfiniteScroll
        key={`${category}`}
        dataLength={items.length}
        next={loadMore}
        hasMore={hasMore}
        loader={<div></div>}
      >
        <div className="grid grid-cols-2 gap-[2px] max-w-3xl auto-rows-fr">
          {items.map((image, index) => (
            <ImageShuffledCard
              className={clsx(getClassName(index), "h-full w-full")}
              key={`${category}-${image.id}-${index}`}
              params={{
                id: image.uuid,
                src: image.imageFile.summaryFileUrl ?? image.imageFile.fileUrl,
                averageScore: image.averageScore,
              }}
              onClick={() => handleChangeScReenType(image.uuid)}
            />
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default RandomizedMasonryGrid;